import { postAPICall } from "../helpers/httpHelper";
import { db } from "../utils/db";

export const fetchUserTypes = async(isMenu = false, params = {}) => {
  let userTypes = await fetchDataFromLocal('user_types');
  if (!userTypes?.length) {
    userTypes = await fetchDataFromDatabase("GET",`/userTypeList?isMenu=${isMenu}`,params);
  }
  return userTypes;
}

export const fetchSingleDataByKey = async (collection, keyFor, value) => {
  try {
    const result = await db[collection].where(keyFor).equals(value).first();
    return result;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const fetchDataFromLocal = async (collection) => {
  // if(params && Object.keys(params)?.length) {
  //   return await db.[collection]
  //   .filter((temp) => temp?.entity_id == params.entity_id || temp?.entity_id?._id == params.entity_id)
  //   .toArray();
  // }
  return await db[collection].toArray();
}

export const fetchDataFromDatabase = async (type, url, params) => {
  let response = await postAPICall(
    type,
    url,
    params
  );
  return response?.data;
};

